import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Settings, Package, DollarSign, TrendingUp, Search, Download, Filter, Edit, Tag, Calendar, Mail } from 'lucide-react';
import { useAuthTimer } from '../hooks/useAuthTimer';
import '../stylings/AdminDashboard.css';
import OrderDetailsModal from './OrderDetailsModal';
import SettingsModal from './SettingsModal'; // Add this import


const API_ENDPOINT = 'https://ic0qgh23he.execute-api.us-east-1.amazonaws.com/default/mellowOrdersGet';
const SENT_OUT_API_ENDPOINT = 'https://ic0qgh23he.execute-api.us-east-1.amazonaws.com/default/mellowOrderSentOut';

// Format items helper function
const formatItems = (items) => {
  const itemsArray = Array.isArray(items) ? items : JSON.parse(items);
  return (
    <div className="order-items-list">
      {itemsArray.map((item, index) => (
        <div key={index} className="order-item">
          <span className="item-quantity">{item.quantity}x</span>
          <span className="item-title">{item.title}</span>
          <span className="item-price">${item.totalPrice.toFixed(2)}</span>
        </div>
      ))}
    </div>
  );
};

const AdminDashboard = () => {
  useAuthTimer(); // Add this near the top with other hooks
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [timeRange, setTimeRange] = useState('7days');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({
    totalRevenue: 0,
    totalOrders: 0,
    averageOrderValue: 0,
    recentOrders: []
  });
  const [revenueData, setRevenueData] = useState([]);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [coupons, setCoupons] = useState([]);
  const [couponSearchTerm, setCouponSearchTerm] = useState('');
  const [couponFilter, setCouponFilter] = useState('all');
  const [couponStats, setCouponStats] = useState({
    total_coupons: 0,
    used_coupons: 0,
    expired_coupons: 0
  });


  useEffect(() => {
    fetchOrders();
  }, [filterStatus, searchTerm]);

  const fetchOrders = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        status: filterStatus,
        search: searchTerm
      }).toString();
  
      const response = await fetch(`${API_ENDPOINT}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log('Response from API:', responseData);
  
      // Check if we received the orders directly
      if (responseData.orders) {
        setOrders(responseData.orders);
        calculateAnalytics(responseData.orders);
        generateRevenueData(responseData.orders);
      } else if (responseData.body) {
        // Handle Lambda proxy integration format if present
        const parsedBody = typeof responseData.body === 'string' 
          ? JSON.parse(responseData.body) 
          : responseData.body;
          
        if (parsedBody.orders) {
          setOrders(parsedBody.orders);
          calculateAnalytics(parsedBody.orders);
          generateRevenueData(parsedBody.orders);
        } else {
          throw new Error('No orders data found in response');
        }
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError(`Unable to load orders: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSentOut = async (orderId) => {
    try {
      const response = await fetch(SENT_OUT_API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ order_id: orderId }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update order status');
      }
  
      const data = await response.json();
      console.log(data.message); // Log success message
  
      // Refresh the orders list to reflect the change
      await fetchOrders();
    } catch (error) {
      console.error('Error updating order status:', error);
      setError('Failed to update order status. Please try again.');
    }
  };

  const handleEdit = (order) => {
    setSelectedOrder(order);
  };
  

  const calculateAnalytics = (orderData) => {
    const revenue = orderData.reduce((sum, order) => sum + parseFloat(order.total_amount), 0);
    const averageOrder = orderData.length > 0 ? revenue / orderData.length : 0;

    setAnalyticsData({
      totalRevenue: revenue,
      totalOrders: orderData.length,
      averageOrderValue: averageOrder,
      recentOrders: orderData.slice(0, 5)
    });
  };

  const generateRevenueData = (orderData) => {
    const revenueByDate = orderData.reduce((acc, order) => {
      const date = new Date(order.order_date).toISOString().split('T')[0];
      acc[date] = (acc[date] || 0) + parseFloat(order.total_amount);
      return acc;
    }, {});

    const chartData = Object.entries(revenueByDate)
      .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
      .map(([date, revenue]) => ({ date, revenue: Number(revenue.toFixed(2)) }));

    setRevenueData(chartData);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleExport = () => {
    const csvContent = [
      ['Order ID', 'Customer', 'Email', 'Address', 'City', 'Zip Code', 'Items', 'Total Amount', 'Discount Code', 'Order Date'],
      ...orders.map(order => [
        order.order_id,
        order.full_name,
        order.email,
        order.address,
        order.city,
        order.zip_code,
        typeof order.items === 'string' ? order.items : JSON.stringify(order.items),
        order.total_amount,
        order.discount_code || 'None',
        new Date(order.order_date).toLocaleDateString()
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `mellow-heights-orders-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleCouponExport = () => {
    const csvContent = [
      ['Email', 'Discount Code', 'Status', 'Code Sent', 'Code Used', 'Expiration Date'],
      ...coupons.map(coupon => [
        coupon.email,
        coupon.discount_code,
        coupon.code_used ? 'Used' : 'Active',
        coupon.code_sent ? 'Yes' : 'No',
        coupon.code_used ? 'Yes' : 'No',
        coupon.formatted_date
      ])
    ].map(row => row.join(',')).join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `mellow-heights-coupons-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };


  const fetchCoupons = async () => {
    try {
      const queryParams = new URLSearchParams({
        status: couponFilter,
        search: couponSearchTerm
      }).toString();
  
      const response = await fetch(`https://sbojfulrpk.execute-api.us-east-1.amazonaws.com/default/mellow-admin-get-coupons?${queryParams}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setCoupons(data.coupons);
      setCouponStats(data.statistics);
    } catch (error) {
      console.error('Error fetching coupons:', error);
      setError('Failed to load coupon data');
    }
  };
  
  // Add this useEffect
  useEffect(() => {
    fetchCoupons();
  }, [couponFilter, couponSearchTerm]);
  

  return (
    <div className="admin-dashboard">
      <div className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <div className="header-actions">
        <button 
          className="portal-btn"
          onClick={() => window.open('https://www.theradicalsoftware.com/client-portal', '_blank')}
        >
          Radical Portal
          <TrendingUp size={20} />
        </button>
        <button className="settings-btn" onClick={() => setIsSettingsOpen(true)}>
          <Settings size={20} />
          Settings
        </button>
        </div>
      </div>

      <div className="analytics-cards">
        <div className="analytics-card">
          <div className="card-icon">
            <DollarSign size={24} />
          </div>
          <div className="card-content">
            <h3>Total Revenue</h3>
            <p className="card-value">${analyticsData.totalRevenue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}</p>
          </div>
        </div>

        <div className="analytics-card">
          <div className="card-icon">
            <Package size={24} />
          </div>
          <div className="card-content">
            <h3>Total Orders</h3>
            <p className="card-value">{analyticsData.totalOrders}</p>
          </div>
        </div>

        <div className="analytics-card">
          <div className="card-icon">
            <TrendingUp size={24} />
          </div>
          <div className="card-content">
            <h3>Average Order Value</h3>
            <p className="card-value">${analyticsData.averageOrderValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}</p>
          </div>
        </div>
      </div>

      <div className="chart-section">
        <h2>Revenue Overview</h2>
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={revenueData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip 
                formatter={(value) => [`$${value.toLocaleString()}`, 'Revenue']}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="revenue" 
                stroke="#8fc760" 
                strokeWidth={2}
                dot={{ fill: '#8fc760' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="orders-section">
        <div className="orders-header">
          <h2>Recent Orders</h2>
          <div className="orders-actions">
            <div className="search-bar">
              <Search size={20} />
              <input
                type="text"
                placeholder="Search orders..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            <select 
              className="filter-select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Orders</option>
              <option value="pending">Pending</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
            <button className="export-btn" onClick={handleExport}>
              <Download size={20} />
              Export
            </button>
          </div>
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        <div className="orders-table-container">
          {isLoading ? (
            <div className="loading-indicator">Loading orders...</div>
          ) : (
            <table className="orders-table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Customer</th>
                  <th>Email</th>
                  <th>Items</th>
                  <th>Total Amount</th>
                  <th>Order Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.order_id}>
                    <td>#{order.order_id}</td>
                    <td>{order.full_name}</td>
                    <td>{order.email}</td>
                    <td>{formatItems(order.items)}</td>
                    <td>${parseFloat(order.total_amount).toFixed(2)}</td>
                    <td>{new Date(order.order_date).toLocaleDateString()}</td>
                    <td>
                    {order.sent_out ? (
                      <span className="sent-badge">Sent</span>
                    ) : (
                      <span className="pending-badge">Pending</span>
                    )}
                  </td>
                  <td className="actions-cell">
                    <button 
                      className="action-btn"
                      onClick={() => setSelectedOrder(order)}
                    >
                      View
                    </button>
                    {!order.sent_out && (
                      <button 
                        className="action-btn sent-out-btn"
                        onClick={() => handleSentOut(order.order_id)}
                      >
                        Send Out
                      </button>
                    )}
                    {order.sent_out && (
                      <button 
                        className="action-btn edit-btn"
                        onClick={() => handleEdit(order)}
                      >
                        <Edit size={16} />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )}
        </div>
      </div>

      {/* Coupon Tracking Section */}
      <div className="coupon-section">
        <div className="coupon-header">
          <h2>Coupon Tracking</h2>
          <div className="coupon-controls">
            <div className="search-bar">
              <Search size={20} />
              <input
                type="text"
                placeholder="Search coupons or emails..."
                value={couponSearchTerm}
                onChange={(e) => setCouponSearchTerm(e.target.value)}
              />
            </div>
            <select 
              className="filter-select"
              value={couponFilter}
              onChange={(e) => setCouponFilter(e.target.value)}
            >
              <option value="all">All Coupons</option>
              <option value="used">Used</option>
              <option value="unused">Unused</option>
              <option value="expired">Expired</option>
            </select>
            <button className="export-btn" onClick={handleCouponExport}>
              <Download size={20} />
              Export Coupons
            </button>
          </div>
        </div>

        <div className="coupon-stats">
          <div className="stat-card">
            <div className="stat-icon">
              <Tag className="icon" />
            </div>
            <div className="stat-content">
              <span className="stat-label">Total Coupons</span>
              <span className="stat-value">{couponStats.total_coupons}</span>
            </div>
          </div>
          <div className="stat-card">
            <div className="stat-icon">
              <Mail className="icon" />
            </div>
            <div className="stat-content">
              <span className="stat-label">Used Coupons</span>
              <span className="stat-value">{couponStats.used_coupons}</span>
            </div>
          </div>
          <div className="stat-card">
            <div className="stat-icon">
              <Calendar className="icon" />
            </div>
            <div className="stat-content">
              <span className="stat-label">Expired Coupons</span>
              <span className="stat-value">{couponStats.expired_coupons}</span>
            </div>
          </div>
        </div>

        <div className="coupon-table-container">
          <table className="coupon-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Discount Code</th>
                <th>Status</th>
                <th>Expiration</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coupon) => (
                <tr key={coupon.id}>
                  <td>{coupon.email}</td>
                  <td>
                    <span className="coupon-code">{coupon.discount_code}</span>
                  </td>
                  <td>
                    <span className={`status-badge ${coupon.code_used ? 'used' : 'active'}`}>
                      {coupon.code_used ? 'Used' : 'Active'}
                    </span>
                  </td>
                  <td>
                    <span className={`expiration-date ${new Date(coupon.expiration_date) < new Date() ? 'expired' : ''}`}>
                      {coupon.formatted_date}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Order Details Modal */}
      {selectedOrder && (
        <OrderDetailsModal
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
        />
      )}
      <SettingsModal isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </div>
  );
};

export default AdminDashboard;